import React, {useContext, useState} from 'react';
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Form, Input, Select, Row, Col, Switch } from "antd";

import {ICausal, ISeller} from 'interfaces';
import {StoreContext} from '../../contexts/StoreContext';

export const CausalEdit: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {formProps, saveButtonProps, queryResult} = useForm<ICausal>({
    });

    const { selectProps: sellerSelectProps } = useSelect<ISeller>({
        resource: "sellers",
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.seller?.id,

        pagination: {
            mode: "server"
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={store.id ? 8 : 5}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={store.id ? 3 : 5}>
                        <Form.Item
                            label="Ordinamento"
                            name="ordinamento"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Acronimo"
                            name="acronimo"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6} hidden={store?.id}>
                        <Form.Item
                            label="Seller"
                            name="seller"
                            rules={[
                                {
                                    required: !store?.id,
                                },
                            ]}
                        >
                            <Select {...sellerSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label="Entrata"
                            name="isLoad"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label="Uscita"
                            name="isUnLoad"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label="Turno"
                            name="isTurn"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
