import {
    IResourceComponentsProps,
    useCreate,
    useCustomMutation,
    useExport,
    useList,
    useTranslate
} from '@refinedev/core';

import {
    List, TagField, useModal
} from '@refinedev/antd';


import {Button, Col, Input, message, Modal, Row, Space, Table, Upload, UploadFile, UploadProps} from 'antd';
import React, {useContext, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import * as XLSX from 'xlsx';
import {IStatusWareHouse} from '../../interfaces';
import {UploadOutlined} from '@ant-design/icons';

export const ImportList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const importXls = useRef<any>(null);
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState<any>([]);
    const { show, close: closeModal, modalProps } = useModal();
    const [loading, setLoading] = useState(false);

    const getPosition = useCreate();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);

    const handleUpload = () => {
        setUploading(true);
        fileList.forEach((file: any) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const data = e?.target?.result;
                let readedData = XLSX.read(data, {type: 'binary'});
                const wsName = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsName];

                /* Convert array to json*/
                const dataParse: any = XLSX.utils.sheet_to_json(ws, {header:1});
                setColumns(dataParse[0].map((r: any) => ({ key: r, name: r })));
                const dati = dataParse.slice(1).map((r: any) => r.reduce((acc: any, x: any, i: any) => {
                    acc[dataParse[0][i]] = x;
                    return acc;
                }, {}));
                console.log(dati);
                const imp = dati.map((r: any) => ({ ams: r['Codice AAMS'], quantita: r['Quantita'], unita_minima: r['Unita Minima [Kgc]'], descrizione: r['Descrizione'] }));
                // console.log(imp);
                getPosition.mutateAsync({
                    successNotification: {
                        message: 'Dati importati correttamente',
                        type: 'success',
                    },
                    resource: 'custom/getPositionFromAms',
                    values: {
                        ams: imp.sort((a: any, b: any) => a.ams.toString().localeCompare(b.ams.toString())),
                        seller: store.id,
                    }
                }).then((res: any) => {
                    // console.log(res);
                    setRows(res.data);
                    closeModal();
                })
            };
            reader.readAsBinaryString(file)
        });
    };

    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    // const importXLS = (e: any) => {
    //     e.preventDefault();
    //
    //     const files = e.target.files, f = files[0];
    //     const reader = new FileReader();
    //     reader.onload = function (e) {
    //         const data = e?.target?.result;
    //         let readedData = XLSX.read(data, {type: 'binary'});
    //         const wsName = readedData.SheetNames[0];
    //         const ws = readedData.Sheets[wsName];
    //
    //         /* Convert array to json*/
    //         const dataParse: any = XLSX.utils.sheet_to_json(ws, {header:1});
    //         setColumns(dataParse[0].map((r: any) => ({ key: r, name: r })));
    //         const dati = dataParse.slice(1).map((r: any) => r.reduce((acc: any, x: any, i: any) => {
    //             acc[dataParse[0][i]] = x;
    //             return acc;
    //         }, {}));
    //         console.log(dati);
    //         const imp = dati.map((r: any) => ({ ams: r['Codice AAMS'], quantita: r['Quantita'], unita_minima: r['Unita Minima [Kgc]'], descrizione: r['Descrizione'] }));
    //         // console.log(imp);
    //         getPosition.mutateAsync({
    //             successNotification: {
    //                 message: 'Dati importati correttamente',
    //                 type: 'success',
    //             },
    //             resource: 'custom/getPositionFromAms',
    //             values: {
    //                 ams: imp.sort((a: any, b: any) => a.ams.toString().localeCompare(b.ams.toString())),
    //                 seller: store.id,
    //             }
    //         }).then((res: any) => {
    //             // console.log(res);
    //             setRows(res.data);
    //             closeModal();
    //         })
    //     };
    //     reader.readAsBinaryString(f)
    // }

    const {data: statusWareHouse} = useList<IStatusWareHouse>({
        resource: "status-ware-houses",
        meta: {populate: 'seller, device, led'},

        queryOptions: {
            enabled: true
        },

        pagination: {
            mode: 'server',
            pageSize: 9999999
        },
        filters: [
            {
                field: "seller.id",
                operator: "eq",
                value: store.id,
            },
            {
                field: 'led][device_type][isLuminaria]',
                operator: 'eq',
                value: true
            }
        ]
    });

    const showModal = () => {
        setFileList([]);
        setRows([]);
        setLoading(false);
        show();
    }

    const callLuminara = useCreate();
    const accendiLuminaria = () => {
        if (rows && rows.length > 0) {
            setLoading(true);
            // console.log(data);
            for (let p of rows) {
                // console.log("Position: ", p.position);
                if (p.position && p.position.length > 0) {
                    for (let c of p.position) {
                        const position = c;
                        console.log("Position Split: ", position);
                        if (position) {
                            const getModule = position.split(/[a-zA-Z]/g);
                            const getColumn = position.match(/[a-zA-Z]/g);
                            const nModulo = getModule[0];
                            const nPosition = getModule[1];
                            const nColumn = getColumn[0];
                            const findDevice: any = statusWareHouse?.data.find((d: any) => (d.device.deviceNumber === Number(nModulo) && String.fromCharCode(d.module + 65) === nColumn));
                            // const findDevice: any = statusWareHouse?.data.find((d: any) => (d.device.deviceNumber === Number(position.substring(0, 1)) && String.fromCharCode(d.module + 65) === position.substring(1, 2)));
                            // console.log("Dispositivo Trovato: ", findDevice);
                            // console.log("Dispositivo Number: ", nPosition);
                            // console.log("Dispositivo Module: ", nModulo);
                            // console.log("Dispositivo Colonna: ", nColumn);
                            if (findDevice) {
                                // const numeroCella = position.length <= 3 ? position.substring(2, 3) : position.substring(2, 4);
                                const numeroCella = nPosition;
                                // console.log(numeroCella);
                                const serialeLed = findDevice?.led?.serial;
                                // console.log('Seriale LED: ', serialeLed);
                                const findRiga = Math.floor(numeroCella/2) + 1;
                                let led = '';
                                setTimeout(() => {
                                    for(let r of findDevice["riga" + findRiga].split(',')) {
                                        // console.log(String(r).padStart(4, '0'));
                                        led += String(r).padStart(4, '0') + "," + (numeroCella % 2 === 0 ? 'S' : 'D') + ",1;";
                                    }
                                    // console.log(led);
                                    const nLed = String(findDevice["riga" + findRiga].split(',').length).padStart(6, '0');
                                    // console.log(nLed);
                                    callLuminara.mutateAsync({
                                        resource: 'custom/controlloLuminaria',
                                        successNotification: false,
                                        values: {
                                            comando: `[${serialeLed}|${nLed}|${led}]`,
                                            seller: store.id,
                                            isPrenotazione: false
                                        }
                                    }).then((res: any) => {
                                        message.info("Dati inviati al Master LED!");
                                    })
                                }, 1000)
                            }
                        }
                    }
                    setTimeout(() => {
                        setLoading(false);
                    }, 3000)

                }
            }
        } else {
            message.error("Devi prima importare il file XLS!");
        }
    }


    return (
        <>
            <List
                title={translate('pages.import.title', 'Xls -> IntelliLed')}
                headerProps={{
                    extra: [
                        <>
                            <Button type={'primary'} onClick={showModal}>Import</Button>
                            <Button type={'primary'} style={{ backgroundColor: '#ff00db'}} loading={loading} onClick={accendiLuminaria}>Accendi Intelli Led</Button>
                        </>
                    ],
                }}
            >
                <Table size="small" dataSource={rows} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("parameter", record);
                               },
                           };
                       }}
                       sticky={true}
                >
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex="ams"
                        key="ams"
                        title={translate('pages.import.progressive', 'Codice Ams')}
                    />
                    <Table.Column
                        dataIndex="descrizione"
                        key="descrizione"
                        title={translate('pages.import.descrizione', 'Descrizione')}
                        render={(value) => value }
                    />
                    <Table.Column
                        dataIndex="quantita"
                        key="quantita"
                        title={translate('pages.import.quantita', 'Quantità')}
                        render={(value) => value }
                    />
                    <Table.Column
                        dataIndex="unita_minima"
                        key="unita_minima"
                        title={translate('pages.import.quantita', 'Unità Minima')}
                        render={(value) => value }
                    />
                    <Table.Column
                        dataIndex="qta"
                        key="qta"
                        title={translate('pages.import.quantita', 'Qta')}
                        render={(value) => value }
                    />
                    <Table.Column
                        dataIndex="position"
                        key="position"
                        title={translate('pages.import.position', 'Position')}
                        render={(value) =>
                            value?.map((x: any, index: number) => {
                                return (<TagField color={'green'} value={x || ''} />)
                        })}
                    />

                </Table>
            </List>
            <Modal {...modalProps}
                   footer={<>
                       <Row gutter={24}>
                           <Col span={24}>
                               <Button
                                   type="primary"
                                   onClick={handleUpload}
                                   disabled={fileList.length !== 1}
                                   loading={uploading}
                                   style={{ marginTop: 16 }}
                               >
                                   {uploading ? 'Uploading' : 'Conferma'}
                               </Button>
                           </Col>
                       </Row>
                   </>}
                   width={'40%'}
                   title={'Carica file XLS - MAX 1 FILE!'}
                   // cancelText={'Annulla'}
                   // okText={'Conferma'}
                   // onOk={importXLS}
            >
                <Row gutter={24}>
                    <Col span={24} style={{marginBottom: 20}}>
                        <hr/>
                        <Upload {...props} maxCount={1}
                                multiple>
                            <Button icon={<UploadOutlined/>}>Seleziona File XLS</Button>
                        </Upload>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
