import React, {useContext, useState} from 'react';
import {IResourceComponentsProps, useCreate, useCustom, useCustomMutation, useList} from '@refinedev/core';

import {
    Edit,
    useForm,
    useSelect,
    useTable,
    TagField,
    ShowButton,
    EditButton,
    DeleteButton,
    CloneButton, useModal, useModalForm,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Switch,
    Table,
    Popover,
    Button,
    Space,
    Divider, Modal, Typography, message,
} from 'antd';

import {IDevice, IDeviceType, ISeller, IStatusWareHouse, IWareHousePosition} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {API_URL} from '../../constants';
const {Title} = Typography;


export const DeviceEdit: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {formProps, saveButtonProps, queryResult} = useForm<IDevice>({
        meta: {populate: "*"},
    });

    const { selectProps: deviceSelectProps } = useSelect<IDeviceType>({
        resource: "device-types",
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.device_type?.id,

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: deviceSelect } = useSelect<IDevice>({
        resource: "devices",
        meta: {
          populate: '*'
        },
        optionValue: 'id',
        optionLabel: 'description',
        filters: [
            {
                field: 'device_type][isLuminaria]',
                operator: 'eq',
                value: true
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    // const { selectProps: sellerSelectProps } = useSelect<ISeller>({
    //     resource: "sellers",
    //     optionValue: 'id',
    //     optionLabel: 'description',
    //     defaultValue: queryResult?.data?.data?.seller?.id
    // });
    const {tableProps, tableQueryResult} = useTable<IStatusWareHouse>({
        resource: "status-ware-houses",
        meta: {populate: 'seller, device, led'},

        queryOptions: {
            enabled: true
        },

        filters: {
            permanent: [
                    {
                        field: "seller.id",
                        operator: "eq",
                        value: store.id,
                    },
                    {
                        field: "device][id]",
                        operator: 'eq',
                        value: queryResult?.data?.data?.id
                    }
                ]
        }
    });

    const addModules = useCreate<IStatusWareHouse>();
    const addModule = () => {
        const maxValue: any = tableQueryResult.data?.data.reduce((prev, current) => (prev.module > current.module) ? prev : current);
        // console.log(maxValue)
        addModules.mutateAsync({
            resource: 'status-ware-houses',
            values: {
                device: queryResult?.data?.data?.id,
                seller: tableQueryResult.data?.data[0].seller.id,
                module:  Number(maxValue.module) +  1
            }
        })
    }

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm<IStatusWareHouse>({
        resource: 'status-ware-houses',
        meta: {
          populate: '*'
        },
        action: "edit",
        warnWhenUnsavedChanges: true,
    });


    const callLuminara = useCreate();
    const sendLuminaria = (record: any, value: any) => {
        console.log(record, value);
        const findRiga = Math.floor(value/2) + 1;
        console.log(record["riga" + findRiga]);
        let led = '';
        for(let r of record["riga" + findRiga].split(',')) {
            console.log(String(r).padStart(4, '0'));
            led += String(r).padStart(4, '0') + "," + (value % 2 === 0 ? 'S' : 'D') + ",1;";
        }
        console.log(led);
        const nLed = String(record["riga" + findRiga].split(',').length).padStart(6, '0')
        callLuminara.mutateAsync({
            resource: 'custom/controlloLuminaria',
            successNotification: false,
            values: {
                comando: `[${record.led.serial}|${nLed}|${led}]`,
                seller: 0,
                isPrenotazione: false,
            }
        }).then((res: any) => {
            message.info("Dati Inviati Correttamente!")
        })
    }

    const testLed = (value: any, onOff: boolean, leftRight: string) => {
        const serial = editFormProps.form?.getFieldValue('led');
        const on_off = onOff ? 1 : 0;
        let led = '';
        for(let r of value.split(',')) {
            console.log(String(r).padStart(4, '0'));
            led += String(r).padStart(4, '0') + "," + leftRight + "," + on_off + ";";
        }
        console.log(led);
        const nLed = String(value.split(',').length).padStart(6, '0')
        callLuminara.mutateAsync({
            resource: 'custom/controlloLuminaria',
            successNotification: false,
            values: {
                comando: `[${serial.serial}|${nLed}|${led}]`,
                seller: 0,
                isPrenotazione: false,
            }
        }).then((res: any) => {
            message.info("Dati Inviati Correttamente!")
        })
    }

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Dispositivo"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={2}>
                        <Form.Item
                            label="Numero Dispositivo"
                            name="deviceNumber"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Seriale"
                            name="serial"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label="Indirizzo IP"
                            name="ip"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Numero Moduli"
                            name="deviceCount"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Ver. Soft"
                            name="versioneSoftware"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input placeholder={"1.0"} />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label="Device Type"
                            name={["device_type", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...deviceSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Unico Turno"
                            name="isUnicoTurno"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Update?"
                            name="isUpdate"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Restart?"
                            name="isReset"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    {/*<Col span={8}>*/}
                    {/*    <Form.Item*/}
                    {/*        label="Seller"*/}
                    {/*        name={["seller", "id"]}*/}
                    {/*        rules={[*/}
                    {/*            {*/}
                    {/*                required: true,*/}
                    {/*            },*/}
                    {/*        ]}*/}
                    {/*    >*/}
                    {/*        <Select {...sellerSelectProps} />*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                </Row>
            </Form>
            <Row gutter={24}>
                <Col span={8}>
                    <Button type={'primary'} onClick={addModule}>Aggiungi Modulo</Button>
                </Col>
            </Row>
            <Divider></Divider>
            <Row gutter={24}>
                <Col span={24}>
                    <Table {...tableProps}>
                        <Table.Column
                            dataIndex="device"
                            key="device"
                            title="Master"
                            align={'center'}
                            // render={(value) => String.fromCharCode(value + 65)}
                            render={(value) => <TagField color={'blue'} value={value.deviceNumber} />}
                        />
                        <Table.Column
                            dataIndex="module"
                            key="module"
                            title="Modulo"
                            align={'center'}
                            // render={(value) => String.fromCharCode(value + 65)}
                            render={(value, record: any) => <TagField color={'yellow'} style={{cursor: 'pointer'}} value={String.fromCharCode(value + 65)} onClick={() => editModalShow(record.id)}/>}
                        />
                        <Table.Column
                            dataIndex="led"
                            key="led"
                            title="Led"
                            align={'center'}
                            // render={(value) => String.fromCharCode(value + 65)}
                            render={(value) => <TagField color={'yellow'} value={value?.description} />}
                        />
                        <Table.Column
                            dataIndex="col1"
                            key="col1"
                            align={'center'}
                            title={'Col1'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 0)}/>
                           }
                        />
                        <Table.Column
                            dataIndex="col2"
                            key="col2"
                            align={'center'}
                            title={'Col2'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 1)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col3"
                            key="col3"
                            align={'center'}
                            title={'Col3'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 2)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col4"
                            key="col4"
                            align={'center'}
                            title={'Col4'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 3)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col5"
                            key="col5"
                            align={'center'}
                            title={'Col5'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 4)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col6"
                            key="col6"
                            align={'center'}
                            title={'Col6'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 5)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col7"
                            key="col7"
                            align={'center'}
                            title={'Col7'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 6)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col8"
                            key="col8"
                            align={'center'}
                            title={'Col8'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 7)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col9"
                            key="col9"
                            align={'center'}
                            title={'Col9'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 8)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col10"
                            key="col10"
                            align={'center'}
                            title={'Col10'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 9)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col11"
                            key="col11"
                            align={'center'}
                            title={'Col11'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 10)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col12"
                            key="col12"
                            align={'center'}
                            title={'Col12'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 11)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col13"
                            key="col13"
                            align={'center'}
                            title={'Col13'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 12)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col14"
                            key="col14"
                            align={'center'}
                            title={'Col14'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 13)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col15"
                            key="col15"
                            align={'center'}
                            title={'Col15'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 14)}/>
                            }
                        />
                        <Table.Column
                            dataIndex="col16"
                            key="col16"
                            align={'center'}
                            title={'Col16'}
                            render={(value, record: any) =>
                                <TagField color={value ? 'green' : 'red'} value={value} style={{cursor: 'pointer'}} onClick={() => sendLuminaria(record, 15)}/>
                            }
                        />
                        <Table.Column<{ id: string }>
                            title={'Azioni'}
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    <DeleteButton
                                        resource={'status-ware-houses'}
                                        mutationMode={'undoable'}
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                </Col>
            </Row>
            <Modal {...editModalProps} title={'Interfaccia Configurazione Luminaria'}>
                <Form {...editFormProps} layout="vertical">
                    <Title level={4}>Modulo: {String.fromCharCode(editFormProps.form?.getFieldValue('module') + 65)}</Title>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Device"
                                name={["led", "id"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select {...deviceSelect} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={14}>
                        <Col span={16}>
                            <Form.Item
                                label="VANO 0-1"
                                name="riga1"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led S"
                                name="test1s"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga1'), checked, 'S')} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led D"
                                name="test1d"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga1'), checked, 'D')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={14}>
                        <Col span={16}>
                            <Form.Item
                                label="VANO 2-3"
                                name="riga2"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led S"
                                name="test2s"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga2'), checked, 'S')} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led D"
                                name="test2d"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga2'), checked, 'D')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={14}>
                        <Col span={16}>
                            <Form.Item
                                label="VANO 4-5"
                                name="riga3"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led S"
                                name="test3s"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga3'), checked, 'S')} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led D"
                                name="test3d"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga3'), checked, 'D')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={14}>
                        <Col span={16}>
                            <Form.Item
                                label="VANO 6-7"
                                name="riga4"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led S"
                                name="test4s"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga4'), checked, 'S')} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led D"
                                name="test4d"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga4'), checked, 'D')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={14}>
                        <Col span={16}>
                            <Form.Item
                                label="VANO 8-9"
                                name="riga5"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led S"
                                name="test5s"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga5'), checked, 'S')} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led D"
                                name="test5d"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga5'), checked, 'D')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={14}>
                        <Col span={16}>
                            <Form.Item
                                label="VANO 10-11"
                                name="riga6"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led S"
                                name="test6s"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga6'), checked, 'S')} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led D"
                                name="test6d"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga6'), checked, 'D')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={14}>
                        <Col span={16}>
                            <Form.Item
                                label="VANO 12-13"
                                name="riga7"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                key="test1"
                                label="Test Led S"
                                name="test7s"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga7'), checked, 'S')} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led D"
                                name="test7d"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga7'), checked, 'D')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={14}>
                        <Col span={16}>
                            <Form.Item
                                label="VANO 14-15"
                                name="riga8"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led S"
                                name="test8s"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga8'), checked, 'S')} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Test Led D"
                                name="test8d"
                            >
                                <Switch size={'default'} unCheckedChildren={'OFF'} checkedChildren={'ON'} onClick={(checked) => testLed(editFormProps.form?.getFieldValue('riga8'), checked, 'D')} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Edit>
    );
};
