import React, {useContext, useEffect, useRef, useState} from 'react';
import {useForm, useSelect} from '@refinedev/antd';
import {Button, Form, Input, InputRef, Modal, Select, Space, Switch, Typography} from 'antd';

import {IDevice, ISeller, IUser} from 'interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
import {useGetIdentity, useList, useOne, useUpdate} from '@refinedev/core';
import {DeviceContext} from '../../contexts/DeviceContext';
import {useLocation} from 'react-router-dom';
import * as Icons from '@ant-design/icons';
const { Text, Title } = Typography;
type SelectProps = {
    onSelect: () => void;
};

export const DeviceSelect: React.FC<SelectProps> = ({ onSelect }) => {
    const { data: identity } = useGetIdentity<{ id: number; fullName: string}>();
    const [device, setDevice] = useContext(DeviceContext);
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const [userDevice, setUserDevice] = useState('');
    const utilityHelper = UtilityHelper(API_URL + "/api");
    const location = useLocation();
    const form = useForm();
    const {data: deviceList, isFetched} = useList({
        resource: 'users',
        meta: {
            populate: 'device'
        },
        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'id',
                operator: 'eq',
                value: identity?.id
            }
        ]
    })
    const { selectProps: deviceSelectProps, queryResult } = useSelect<ISeller>({
        resource: "sellers",

        meta: {
            populate: ["devices", "devices.device_type"]
        },

        filters: [
            {
              operator: 'and',
              value: [
                  {
                      field: 'id',
                      operator: 'eq',
                      value: utilityHelper.sellerId()
                  },
              ]
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    useEffect(() => {
        if (!location.pathname.includes('stocks')) {
            localStorage.removeItem('pass');
        }
        if (!location.pathname.includes('restocks')) {
            localStorage.removeItem('passRestock');
        }
    }, [location]);

    useEffect(() => {

        const s: any = localStorage.getItem('device') ? localStorage.getItem('device') : deviceList?.data && deviceList.data.length > 0 ? deviceList?.data[0]?.device : 'undefined';
        if (s === 'undefined') {
            setDevice(null);
        } else {
            setDevice(deviceList?.data && deviceList.data.length > 0 ? deviceList?.data[0].device?.id : JSON.parse(s)?.id);
            setUserDevice(deviceList?.data && deviceList.data.length > 0 ? deviceList?.data[0].device?.id : JSON.parse(s)?.id);
            localStorage.setItem('device', deviceList?.data && deviceList.data.length > 0 ? JSON.stringify(deviceList?.data[0].device) : s);
            form.formProps.form?.setFieldValue('device', deviceList?.data && deviceList.data.length > 0 ? deviceList?.data[0].device?.id : JSON.parse(s)?.id)// setDevice(deviceList?.data[0]?.device?.id);
        }
    });

    const userUpdate = useUpdate<IUser>();
    const handleChange = (selectedValue: string) => {
        setDevice(selectedValue);
        const selectedDevice = options?.filter(x => x.id === Number(selectedValue)) || [];
        localStorage.setItem('device', JSON.stringify(selectedDevice[0]));
        userUpdate.mutate({
            resource: "users",
            id: Number(identity?.id),
            values: {
                device: selectedValue ? selectedValue : null
            },
            successNotification: (data, values, resource) => {
                setDisabilitato(true);
                return {
                    message: 'Dispositivo Impostato Correttamente',
                    description: "Success with no errors",
                    type: "success",
                };
            },
        })
    };

    const options = queryResult?.data?.data[0]?.devices;
    const [disabilitato, setDisabilitato] = useState<boolean>(true);

    // const abilita = (e: any) => {
    //     console.log(e);
    //     setDisabilitato(e);
    // }

    const [passwordSeller, setPasswordSeller] = useState('');
    useEffect(() => {
        utilityHelper.seller().then( (res: any) => {
            setPasswordSeller(res.password);
        });
    }, []);

    const { confirm } = Modal;
    const inputRef: any = useRef<InputRef>(null);
    const showConfirm = () => {
        confirm({
            title: 'Per effettuare la variazione è richiesta una password...',
            icon: <Icons.ExclamationCircleFilled />,
            content: <>
                <Input type={'password'} ref={inputRef} placeholder={'Password...'}></Input>
            </>,
            onOk() {
                if (inputRef.current.input.value === passwordSeller) {
                    localStorage.setItem('pass', inputRef.current.input.value);
                    setDisabilitato(false);
                };
            },
            onCancel() {
                console.log('Cancel');
                setDisabilitato(true);
            },
        });
    };

    return (
        <Form {...form.formProps}>
            <Form.Item label={'Device'} name={'device'}>
                <Select disabled={disabilitato}
                    defaultValue={userDevice}
                    style={{ width: 200 }}
                    onChange={handleChange}
                    onSelect={onSelect}
                    placeholder={'Seleziona Dispositivo Cassa...'}
                >
                    { options?.filter((x: any) => x.device_type?.isCassa).map((option: any) => {
                        return(
                            <Select.Option key={option.id} value={option.id}>
                                {option.description}
                            </Select.Option>
                        )})
                    }
                </Select>
                <Switch checkedChildren={'Abilita'} unCheckedChildren={'Disabilita'} value={disabilitato} style={{marginLeft: 10}} onClick={(e) => showConfirm()} />
            </Form.Item>
        </Form>
    );
};
