import { IResourceComponentsProps, useExport, useTranslate } from "@refinedev/core";

import {
    BooleanField,
    CreateButton,
    DeleteButton,
    EditButton,
    ExportButton,
    List,
    TagField,
    useTable,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {message, Space, Table} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {ICausal} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {DragSortTable, ProColumns} from '@ant-design/pro-components';

export const CausalList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {tableProps, tableQueryResult} = useTable<ICausal>({
        resource: "causals",
        queryOptions: {
          enabled: true
        },
        meta: {populate: '*'},

        filters: {
            permanent: [
                {
                    field: "seller][id]",
                    operator: 'eq',
                    value: store.id
                }
            ]
        }
    });

    const {triggerExport, isLoading: exportLoading} = useExport<ICausal>();

    const columns: ProColumns[] = [
        {
            title: 'Ord',
            dataIndex: 'sort',
            width: 60,
            className: 'drag-visible',
        },
        {
            title: 'Descrizione',
            dataIndex: 'description',
            className: 'drag-visible',
        },
        {
            title: 'Entrata',
            dataIndex: 'isLoad',
        },
        {
            title: 'Uscita',
            dataIndex: 'isUnLoad',
        },
        {
            title: 'Turno',
            dataIndex: 'isTurn',
        },
    ];

    const [dataSource, setDataSource] = useState([]);
    const handleDragSortEnd = (
        beforeIndex: number,
        afterIndex: number,
        newDataSource: any,
    ) => {
        console.log('Data', newDataSource);
        setDataSource(newDataSource);
        message.success('Ok');
    };

    // useEffect(() => {
    //     tableQueryResult.refetch().then((res: any) => {
    //         setDataSource(res.data?.data)
    //     })
    // }, [tableQueryResult]);

    return (
            <List
                title={translate('pages.group.title', 'Causali')}
                headerProps={{
                    extra: [
                        <CreateButton/>,
                        // <ExportButton
                        //     onClick={triggerExport}
                        //     loading={exportLoading}
                        // />
                    ],
                }}
            >
                {/*<DragSortTable*/}
                {/*    showHeader={false}*/}
                {/*    columns={columns}*/}
                {/*    rowKey="id"*/}
                {/*    search={false}*/}
                {/*    pagination={false}*/}
                {/*    dataSource={dataSource}*/}
                {/*    dragSortKey="sort"*/}
                {/*    onDragSortEnd={handleDragSortEnd}*/}
                {/*/>*/}
                <Table size="small" {...tableProps} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("causal", record);
                               },
                           };
                       }}
                       sticky={true}

                       pagination={{
                           ...tableProps.pagination,
                           showSizeChanger: true,
                       }}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title={translate('pages.causal.description', 'Descrizione')}
                    />
                    <Table.Column
                        width={110}
                        dataIndex="ordinamento"
                        key="ordinamento"
                        title={translate('pages.causal.ordinamento', 'Ordinamento')}
                    />
                    <Table.Column
                        dataIndex="isLoad"
                        key="isLoad"
                        title={translate('pages.causal.isLoad', 'Entrata')}
                        render={(value) => (
                            <TagField value={value ? 'SI' : 'NO'} />
                        )}
                    />
                    <Table.Column
                        dataIndex="isUnLoad"
                        key="isUnLoad"
                        title={translate('pages.causal.isUnLoad', 'Uscita')}
                        render={(value) => (
                            <TagField value={value ? 'SI' : 'NO'} />
                        )}
                    />
                    <Table.Column
                        dataIndex="isTurn"
                        key="isTurn"
                        title={translate('pages.causal.isTurn', 'Turno')}
                        render={(value) => (
                            <TagField value={value ? 'SI' : 'NO'} />
                        )}
                    />
                    <Table.Column<{ id: string }>
                        title={translate('pages.users.action', 'Azioni')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />

                </Table>
            </List>
    );
};
