import {
    IResourceComponentsProps,
    useCreate,
    useCustomMutation,
    useExport,
    useList,
    useTranslate
} from '@refinedev/core';

import {
    List, TagField, useModal
} from '@refinedev/antd';
import { saveAs } from 'file-saver';


import {Button, Col, GetProp, Input, message, Modal, Row, Space, Table, Upload, UploadFile, UploadProps} from 'antd';
import React, {useContext, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import * as XLSX from 'xlsx';
import {IStatusWareHouse} from '../../interfaces';
import dayjs from 'dayjs';
import {UploadOutlined} from '@ant-design/icons';

export const UnisciXlsList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const importXls = useRef<any>(null);
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState<any>([]);
    const { show, close: closeModal, modalProps } = useModal();
    const [loading, setLoading] = useState(false);

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);

    const handleUpload = () => {
        setUploading(true);
        fileList.forEach((file: any) => {
            console.log(file)
            const reader = new FileReader();
            reader.onload = function (e) {
                const data = e?.target?.result;
                let readedData = XLSX.read(data, {type: 'binary'});
                const wsName = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsName];

                /* Convert array to json*/
                const dataParse: any = XLSX.utils.sheet_to_json(ws, {header:1});
                setColumns(dataParse[0].map((r: any) => ({ key: r, name: r })));
                const dati = dataParse.slice(1).map((r: any) => r.reduce((acc: any, x: any, i: any) => {
                    if (dataParse[0][i] === 'Quantita') {
                        acc[dataParse[0][i]] = x ? parseFloat(x) : 0;
                    } else {
                        acc[dataParse[0][i]] = x;
                    }
                    return acc;
                }, {}));
                const filterData = dati.sort((a: any, b: any) => a.Descrizione.localeCompare(b.Descrizione)).filter((x: any) => x.Quantita !== 0);
                for (let f of filterData) {
                    if (!f.Quantita) {
                        f.Quantita = 0;
                    }
                    setRows((prevItems: any) => [...prevItems, f]);
                }

            };
            reader.readAsBinaryString(file)
        });
        setTimeout(() => {
            setUploading(false);
            setLoading(true);
        }, 1000)
    };

    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    const mergeFile = () => {
        // console.log(rows);
        const ordine: any = [];
        const ordine_compatto: any = [];
        const sum = rows.map((q: any) => parseFloat(q.Quantita ? q.Quantita : '0').toFixed(2));
        // const total = sum.reduce((acc: any, curr: any) => acc + curr);
        // console.log(rows);
        for (let r of rows) {
            if (r.Quantita) {
                ordine.push({
                    'Codice AAMS': r['Codice AAMS'],
                    'Quantita': r.Quantita,
                    'Descrizione': r.Descrizione,
                    'Tipologia': r.Tipologia,
                    'Unita Minima [Kgc]': r['Unita Minima [Kgc]'],
                    'Prezzo Lordo [Euro/Kgc]': r['Prezzo Lordo [Euro/Kgc]'],
                    'Dazio [Euro/Kgc]': r['Dazio [Euro/Kgc]'],
                    'Decorrenza Prezzo': r['Decorrenza Prezzo'],

                });
                ordine_compatto.push({
                    'Codice AAMS': r['Codice AAMS'],
                    'Quantita': r.Quantita,

                })
            }
        }
        // console.log(ordine);
        const sumQta = ordine.reduce((acc: any, cur: any)=> {
            const found = acc.find((val: any) => Number(val['Codice AAMS']) === Number(cur['Codice AAMS']))
            if(found){
                found['Quantita'] += cur.Quantita;
            }
            else{
                acc.push({...cur, Quantita: cur.Quantita})
            }
            return acc
        }, []);
        const sumQtaCompatto = ordine_compatto.reduce((acc: any, cur: any)=> {
            const found = acc.find((val: any) => Number(val['Codice AAMS']) === Number(cur['Codice AAMS']))
            if(found){
                found['Quantita'] += cur.Quantita;
            }
            else{
                acc.push({...cur, Quantita: cur.Quantita})
            }
            return acc
        }, []);
        // console.log(sumQta);
        const worksheet = XLSX.utils.json_to_sheet(sumQta);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(blob, `Ordine_Aams_${new Date().getTime()}.xlsx`);

        setTimeout(() => {
            const worksheetCompatto = XLSX.utils.json_to_sheet(sumQtaCompatto);
            const workbookCompatto = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbookCompatto, worksheetCompatto, 'Sheet1');
            const excelBufferCompatto = XLSX.write(workbookCompatto, { bookType: 'xlsx', type: 'array' });
            const blobCompatto = new Blob([excelBufferCompatto], {type: 'application/octet-stream'});
            saveAs(blobCompatto, `Ordine_Compatto_Aams_${new Date().getTime()}.xlsx`);
        }, 1500);

        closeModal();
    }

    const showModal = () => {
        setFileList([]);
        setRows([]);
        setLoading(false);
        show();
    }

    return (
        <>
            <List
                title={translate('pages.import.title', 'Unisci Xls')}
                headerProps={{
                    extra: [
                        <>
                            <Button type={'primary'} onClick={showModal}>Import</Button>
                        </>
                    ],
                }}
            >
                <Table size="small" dataSource={rows} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("parameter", record);
                               },
                           };
                       }}
                        pagination={false}
                       tableLayout={'auto'}
                       key={'id'}
                        scroll={{y: window.innerHeight - 393 + 'px'}}
                       sticky={true}
                >
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex="Codice AAMS"
                        key="Codice AAMS"
                        title={translate('pages.import.progressive', 'Codice Aams')}
                    />
                    <Table.Column
                        dataIndex="Dazio [Euro/Kgc]"
                        key="Dazio [Euro/Kgc]"
                        title={translate('pages.import.progressive', 'Dazio [Euro/Kgc]')}
                    />
                    <Table.Column
                        dataIndex="Descrizione"
                        key="Descrizione"
                        title={translate('pages.import.descrizione', 'Descrizione')}
                        render={(value) => value }
                    />
                    <Table.Column
                        dataIndex="Prezzo Lordo [Euro/Kgc]"
                        key="Prezzo Lordo [Euro/Kgc]"
                        title={translate('pages.import.progressive', 'Prezzo Lordo [Euro/Kgc]')}
                    />
                    <Table.Column
                        dataIndex="Quantita"
                        key="Quantita"
                        title={translate('pages.import.quantita', 'Quantità')}
                        render={(value) => value }
                    />
                    <Table.Column
                        dataIndex="Tipologia"
                        key="Tipologia"
                        title={translate('pages.import.quantita', 'Tipologia')}
                        render={(value) => value }
                    />
                    <Table.Column
                        dataIndex="Unita Minima [Kgc]"
                        key="Unita Minima [Kgc]"
                        title={translate('pages.import.quantita', 'Unita Minima [Kgc]')}
                        render={(value) => value }
                    />

                </Table>
            </List>
            <Modal {...modalProps}
                footer={<>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Button
                                type="primary"
                                onClick={handleUpload}
                                disabled={fileList.length === 0}
                                loading={uploading}
                                style={{ marginTop: 16 }}
                            >
                                {uploading ? 'Uploading' : '2 - Upload File'}
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                type="primary"
                                onClick={mergeFile}
                                disabled={!loading}
                                style={{ marginTop: 16, backgroundColor: 'green', color: 'white'}}
                            >
                                3 - Fine Unione
                            </Button>
                        </Col>
                    </Row>
                </>}
                   width={'40%'}
                   title={'Carica file XLS'}
                   >
                <Row gutter={24}>
                    <Col span={24} style={{marginBottom: 20}}>
                        <hr/>
                        <Upload {...props} ref={importXls}>
                            <Button icon={<UploadOutlined/>}>1 - Seleziona File XLS</Button>
                        </Upload>
                        {/*<Input ref={importXls} type={'file'} onChange={(e) => importXLS(e)}/>*/}
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
