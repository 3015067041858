import React, {useContext, useEffect, useRef, useState} from 'react';

import {
    IResourceComponentsProps,
    useCreate, useDelete,
    useGetIdentity,
    useList,
    useNotification,
    useUpdate,
} from '@refinedev/core';

import { Edit, useForm, DeleteButton, useSelect, useTable } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Form,
    Input,
    Select,
    Row,
    Col,
    DatePicker,
    Divider,
    Table,
    Space,
    Typography,
    Button,
    InputNumber,
} from "antd";

import {
    IDocType, IPatent, IPatentMovement, IPatentMovementRow,
    IStock,
} from 'interfaces';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import {StoreContext} from '../../contexts/StoreContext';
import {SocketContext} from '../../contexts/socket';

export const PatentMovementEdit: React.FC<IResourceComponentsProps> = () => {
    const soundUrl = '/sounds/bell.mp3';
    const audio = new Audio(soundUrl);
    const inputElement: any = useRef(null) || '';
    const {formProps, saveButtonProps, queryResult, id} = useForm<IPatentMovement>({
        resource: 'patent-movements',
        meta: {
            populate: 'seller, user, doc_type, patent'
        },
    });
    const navigate = useNavigate();
    const socket = useContext(SocketContext);
    const {open, close} = useNotification();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {data: userIdentity} = useGetIdentity<any>();
    const [openRow, setOpenRow] = useState<boolean>(true);
    const [totale, setTotale] = useState(0);
    const updatePatentMovement = useUpdate<IPatentMovement>();

    const {selectProps: docTypeSelectProps} = useSelect<IDocType>({
        resource: 'doc-types',
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.doc_type?.id,
    });

    const {selectProps: patentSelectProps} = useSelect<IPatent>({
        resource: 'patents',
        optionValue: 'id',
        optionLabel: 'company',
        defaultValue: queryResult?.data?.data?.patent?.id,
    });

    const {tableProps, tableQueryResult} = useTable<IPatentMovementRow>({
        resource: 'patent-movement-rows',

        queryOptions: {
            enabled: true
        },

        meta: {
            populate: 'seller, product, stock, patent-movement'
        },

        pagination: {
            pageSize: 1000,
            mode: 'server'
        },

        filters: {
            permanent: [
                {
                    field: 'patent_movement][id]',
                    operator: 'eq',
                    value: id
                }
            ]
        },

        sorters: {
            initial: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        }
    });

    const {Title} = Typography;

    const updateTot = () => {
        if (tableQueryResult.isSuccess && tableQueryResult.data.data.length > 0) {
            // setTotale(parseFloat(Number(tableQueryResult.data?.data.map(item => item?.product?.price * (Math.round(1000 / (item?.product?.priceKg / item?.product?.price)) / 2)).reduce((prev, next) => prev + next)).toFixed(2)));
            setTotale(parseFloat(Number(tableQueryResult.data?.data.map(item => ((item?.price) - ((item?.price) / 100) * item?.aggio)).reduce((prev, next) => prev + next)).toFixed(2)));
        } else {
            setTotale(0)
        }
    }

    useEffect(() => {
        updateTot();
    })

    const enablePatent = () => {
        updatePatentMovement.mutateAsync({
            resource: 'patent-movements',
            successNotification: false,
            id: Number(id),
            values: {
                isPatent: true
            }
        }).then((r: any) => {
            console.log('Successfully Setting isPatent -> true: ', id);
        })
    }
    useEffect(() => {
        socket.on('alertRigaPatentino', async (data: any, error: any) => {
            console.log('PATENTINO:', data);
            tableQueryResult.refetch();
            audio.play();
        });
        return () => {
            socket.off('alertRigaPatentino');
        };
    }, [])

    const onConfirm = (data: any) => {
            updatePatentMovement.mutateAsync({
                resource: 'patent-movements',
                successNotification: false,
                id: Number(id),
                values: {
                    isPatent: false,
                    total: totale,
                    docNumber: data.docNumber,
                }
            }).then((r: any) => {
                console.log('Successfully Edit: ', id);
            })

    }

    const updateStock = useUpdate<IStock>();
    const deletePatentMovementRow = useDelete<IPatentMovementRow>();
    const onDelete = (record: IPatentMovementRow) => {
        deletePatentMovementRow.mutateAsync({
            resource: 'patent-movement-rows',
            successNotification: false,
            id: Number(record.id),
        }).then(rrr => {
            updateStock.mutateAsync({
                successNotification: false,
                resource: 'stocks',
                id: record.stock.id,
                values: {
                    scansiaPz: record.stock.scansiaPz + record.stock.nrPezziStecca
                }
            }).then(r => {
                open?.({
                    type: 'success',
                    message: 'Il prodotto eliminato viene automaticamente caricato in Scansia...',
                    description: 'Attenzione!',
                });
            })
        });
    }
    return (
        <Edit
            title={'Edit Documento'}
            footerButtons={
            <Space>
                <Button type={'primary'} htmlType={'submit'} onClick={formProps.form?.submit} hidden={true}>Salva</Button>
            </Space>

        }>
            <Form
                {...formProps}
                layout="vertical"
                onFinish={onConfirm}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Patentino"
                            name={['patent', 'id']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...patentSelectProps}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Tipo"
                            name={['doc_type', 'id']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...docTypeSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="N. Interno"
                            name="docNumber"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Data"
                            name="loadDate"
                            getValueProps={(value) => ({
                                value: value ? dayjs(value) : '',
                            })}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker style={{width: '100%'}} format={'DD-MM-YYYY'}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Action"
                            name="buttonAction"
                        >
                            <Button hidden={true} style={{width: '100%'}} type={'primary'} htmlType={'button'}
                                    onClick={enablePatent}>Continua</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div>
                <Divider/>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table {...tableProps}
                               pagination={false}
                               scroll={{y: 400}}
                        >
                            <Table.Column
                                dataIndex="product"
                                key="product.codice"
                                title="Codice"
                                render={(value) => value?.codice}
                            />
                            <Table.Column
                                dataIndex="product"
                                key="product_desc"
                                title="Descrizione"
                                render={(value) => value?.description}
                            />
                            <Table.Column
                                dataIndex="price"
                                key="price"
                                title="Price"
                                render={(value, record: IPatentMovementRow) => {
                                    return Number(parseFloat(value).toFixed(2)).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    }) + ' €'
                                }}
                            />
                            <Table.Column
                                dataIndex="aggio"
                                key="aggio"
                                title="Aggio %"
                            />
                            <Table.Column
                                title={'Actions'}
                                dataIndex="actions"
                                render={(_, record: IPatentMovementRow) => (
                                    <Space>
                                        <DeleteButton
                                            hidden={true}
                                            onClick={() => onDelete(record) }
                                            size="small"
                                        />
                                    </Space>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </div>
            <div hidden={!openRow}>
                <Divider/>
                <Title level={3} style={{textAlign: 'right'}}>Stecche Prelevate: {tableQueryResult.data?.data?.length} - Totale: {(
                    Number(parseFloat(totale.toString()).toFixed(2)).toLocaleString('de', {
                        minimumFractionDigits: 2
                    }) + ' €'
                )}</Title>
            </div>
        </Edit>
    );
};
