import React, {FC, useContext, useEffect, useState} from 'react';
import {Badge, Button, message, Space, Spin, Switch, Tooltip, Typography} from 'antd';
// import {SocketContext, socket} from '../../contexts/socket';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
import {socket, SocketContext} from '../../contexts/socket';
import {StoreContext} from '../../contexts/StoreContext';
import {useGo, useList, useOne, useUpdate} from '@refinedev/core';
import {ISeller, IWhareHouseMovement} from '../../interfaces';
import {useTable} from '@refinedev/antd';
import * as Icons from '@ant-design/icons';
interface SelectProps {
    onSelect: () => void;
    theme?: "light" | "dark";
};

export const SocketSelect: FC<SelectProps> = ( props ) => {
    const [isConnected, setIsConnected] = useState(false);
    const socket = useContext(SocketContext);
    // const utilityHelper = UtilityHelper(API_URL + '/api');
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const utilityHelper = UtilityHelper(API_URL + '/api');
    const [magazzinoOff, setMagazzinoOff] = useState(false);
    const {data} = useOne<ISeller>({
        resource: 'sellers',
        id: store.id
    });
    // console.log(data);
    useEffect(() => {
        setInterval(() => {
            //console.log("CHECK", socket.connected);
            setIsConnected(socket.connected);
            refetch().then();
        }, 5000);

    }, []);

    const sendCheck = () => {
        socket.emit("sendMessage", {id: socket.id, data: "CONTROLLO"})
        console.log('CONTROLLO')
    }

    const {data: dataMagazzino, refetch} = useList<IWhareHouseMovement>({
        resource: 'whare-house-movements',
        queryOptions: {
            enabled: false
        },
        filters: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id ? store.id : 0
                },
            {
                field: 'isLoad',
                operator: 'eq',
                value: true
            },
        ]
    });

    useEffect(() => {
        refetch().then();
    }, [store.id]);

    const updateSeller = useUpdate<ISeller>();
    const updateMagazzino = (value: boolean) => {
        updateSeller.mutateAsync({
            resource: 'sellers',
            successNotification: false,
            id: store.id,
            values: {
                isMagazzinoOff: value,
            }
        }).then(rrr => {
            setMagazzinoOff(value);
            message.info("Magazzino " + (value ? 'Non Abilitato' : 'Abilitato'));
        });
    }
    const go = useGo();

    return (
        <Space>
            <Button
                    hidden={dataMagazzino?.data.length === 0}
                    icon={<Icons.LockOutlined />}
                    shape="circle"
                    size="middle"
                    style={{ backgroundColor: 'red', marginRight: 10, marginTop: 20 }}
                    onClick={() => go({
                        to: "/whare-house-movements",
                        type: "push",
                    })}
            />
            <Switch
                style={{width: 180, marginRight: 30}}
                className={(value ? 'green' : 'red')}
                checkedChildren={'Magazzino Disabilitato'}
                unCheckedChildren={'Magazzino Abilitato'}
                checked={data?.data.isMagazzinoOff}
                onChange={(e: any) => {updateMagazzino(e)}}></Switch>
            <Tooltip title={isConnected ? 'Connessione Attiva' : 'Connessione Non Attiva'}>
                <div className={isConnected ? 'led-yellow' : 'led-red'} style={{
                    marginRight: 30
                }} onClick={sendCheck}></div>
            </Tooltip>

        </Space>
    );
};
